import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { withFirebase } from "../../Firebase";
import { requestEvents } from "../../../actions/eventsActions";
import GuestEdit from "./GuestEdit";
import { requestGuests, getGuestCounts } from "../../../actions/guestsActions";
import GuestTable from "./GuestTable";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  table: {
    marginRight: theme.spacing(4),
  },
});

const Guests = ({ classes, firebase, dispatch, guestsObj, history }) => {
  const [guestList, setGuestList] = useState([]);
  useEffect(() => {
    dispatch(requestEvents(firebase));
    dispatch(requestGuests(firebase));
  }, [dispatch, firebase]);
  useEffect(() => {
    setGuestList(Object.values(guestsObj.guests || {}));
  }, [guestsObj.guests]);
  return (
    <>
      <Typography className={classes.root} variant="h2">
        Guests
      </Typography>
      <Switch>
        <Route path="/admin/guests/:id">
          <GuestEdit />
        </Route>
        <Route path="/admin/guests">
          <GuestTable
            loading={guestsObj.loading}
            guests={guestList}
            handleAdd={() => history.push("/admin/guests/new")}
            handleDelete={() => console.log("Delete")}
            handleEdit={id => history.push(`/admin/guests/${id}`)}
          />
        </Route>
      </Switch>
    </>
  );
};

const mapStateToProps = state => {
  const { events, guests } = state;
  return {
    events,
    guestsObj: guests,
    eventCounts: getGuestCounts(state),
    // timeDiff: getTimeSinceLastQuery(events.timestamp),
  };
};

export default withRouter(
  connect(mapStateToProps)(withFirebase(withStyles(styles)(Guests))),
);
