import React from "react";
import TopSVG from "../../svg/TopSVG";

const Header = () => {
  return (
    <div className="header-container">
      <div>
        <TopSVG />
      </div>
      <div>
        <ul className="menu-list">
          <li>
            <a href="/#home">Home</a>
          </li>
          <li>
            <a href="/#ourstory">Our Story</a>
          </li>
          <li>
            <a href="/#details">Event Details</a>
          </li>
          <li>
            <a href="/#wheretostay">Where to Stay</a>
          </li>
          <li>
            <a href="/rsvp">RSVP</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
