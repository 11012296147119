import { combineReducers } from "redux";

import events from "./eventsReducer";
import guests from "./guestsReducer";
import rsvp from "./rsvpReducer";

export default combineReducers({
  events,
  guests,
  rsvp,
});
