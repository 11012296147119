import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core"; //  TextField, Button, IconButton
//import DeleteIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    emailBox: {
      maxWidth: "400px",
    },
    formControl: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    centered: {
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    select: {
      minWidth: "200px",
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
  };
});

const RSVPContact = ({ busSeats, guests, handleUpdate }) => {
  const classes = useStyles();
  const [staying, setStaying] = useState(busSeats ? "yes" : "no");
  const [seats, setSeats] = useState(busSeats);

  const handleChange = e => {
    setStaying(e.target.value);
    if (e.target.value === "no") {
      setSeats(null);
      handleUpdate(null);
    }
  };

  const handleSeatChange = e => {
    if (e.target.value === "") {
      setSeats(null);
      handleUpdate(null);
    } else {
      setSeats(e.target.value);
      handleUpdate(e.target.value);
    }
  };

  const max = (guests.Guests || []).length;
  return (
    <>
      <Typography variant="subtitle2">
        Are you staying at one of our hotels in Uptown Charlotte?
      </Typography>
      <Grid container className={classes.root} spacing={2}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Staying in Hotel"
            name="hotel"
            value={staying}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {staying === "yes" && (
          <div>
            <Typography className={classes.centered} variant="subtitle2">
              A shuttle for guests staying in uptown Charlotte will depart from
              the Charlotte Marriott City Center lobby at 4pm and return at the
              conclusion of the reception. Uber and Lyft are available as well
              as on-site parking.
            </Typography>
            <Typography className={classes.centered} variant="subtitle2">
              If you would like to use the shuttle, please let us know how many
              seats should we reserve for you.
            </Typography>
            <FormControl className={classes.select}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={seats !== null ? seats : ""}
                onChange={handleSeatChange}
                displayEmpty
              >
                <MenuItem value={""}>We will not need the shuttle</MenuItem>
                <MenuItem value={1}>One Guest</MenuItem>
                {max >= 2 && <MenuItem value={2}>Two Guests</MenuItem>}
                {max >= 3 && <MenuItem value={3}>Three Guests</MenuItem>}
                {max >= 4 && <MenuItem value={4}>Four Guests</MenuItem>}
                {max >= 5 && <MenuItem value={5}>Five Guests</MenuItem>}
                {max >= 6 && <MenuItem value={6}>Six Guests</MenuItem>}
                {max >= 7 && <MenuItem value={7}>Seven Guests</MenuItem>}
                {max >= 8 && <MenuItem value={8}>Eight Guests</MenuItem>}
              </Select>
            </FormControl>
          </div>
        )}
      </Grid>
    </>
  );
};

export default RSVPContact;
