import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCr_Q6PbVWew3X41oPJqkOSrVBrJ1658N0",
  authDomain: "wedding-257501.firebaseapp.com",
  databaseURL: "https://wedding-257501.firebaseio.com",
  projectId: "wedding-257501",
  storageBucket: "wedding-257501.appspot.com",
  messagingSenderId: "515743709988",
  appId: "1:515743709988:web:31552c4ae293a3f6dcd1b1",
  measurementId: "G-R8EBQ7CXMJ",
  /*
  apiKey: "AIzaSyCr_Q6PbVWew3X41oPJqkOSrVBrJ1658N0",
  authDomain: "wedding-257501.firebaseapp.com",
  databaseURL: "https://wedding-257501.firebaseio.com",
  projectId: "wedding-257501",
  storageBucket: "wedding-257501.appspot.com",
  messagingSenderId: "515743709988",
  appId: "1:515743709988:web:504d32a701491dfcdcd1b1",
  measurementId: "G-GM8B000LWW",
  */
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  getEvents = () => this.db.collection("events").get();
  updateEvent = key => this.db.collection("events").doc(key);
  addEvent = () => this.db.collection("events").doc();
  getGuests = () => this.db.collection("guests").get();
  updateGuest = key => this.db.collection("guests").doc(key);
  addGuest = () => this.db.collection("guests").doc();
  getDoc = key => this.db.doc(key);
}
export default Firebase;
