import { EVENTS_REQUEST, EVENTS_RETURNED } from "../actions/constants";

export default (
  state = {
    events: [],
    loading: false,
    error: null,
    timestamp: null,
  },
  action,
) => {
  if (action.type === EVENTS_REQUEST) {
    return Object.assign({}, state, {
      events_loading: true,
      events_error: null,
    });
  }
  if (action.type === EVENTS_RETURNED) {
    const newEvents = {};
    Object.entries(action.payload).forEach(([key, value]) => {
      newEvents[key] = { ...value, id: key };
    });
    return Object.assign({}, state, {
      events: newEvents,
      events_loading: false,
      events_error: null,
      timestamp: Date.now(),
    });
  }
  return state;
};
