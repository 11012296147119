import { GUESTS_REQUEST, GUESTS_RETURNED } from "../actions/constants";

export default (
  state = {
    guests: [],
    loading: false,
    error: null,
    timestamp: null,
  },
  action,
) => {
  if (action.type === GUESTS_REQUEST) {
    return Object.assign({}, state, {
      loading: true,
      error: null,
    });
  }
  if (action.type === GUESTS_RETURNED) {
    const newGuests = {};
    Object.entries(action.payload).forEach(([key, value]) => {
      newGuests[key] = { ...value, id: key };
    });
    return Object.assign({}, state, {
      guests: newGuests,
      loading: false,
      error: null,
      timestamp: Date.now(),
    });
  }
  return state;
};
