import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  overlay: {
    position: "absolute",
    backgroundColor: "rgba(100,100,100,0.5)",
    zIndex: 100,
    width: "100%",
    height: "100%",
    textAlign: "center",
    paddingTop: "50px",
    paddingBottom: "-50px",
  },
}));

const LoadingOverlay = ({ children, loading, ...props }) => {
  const classes = useStyles();
  return (
    <div {...props} style={{ position: "relative" }}>
      {loading && (
        <div className={classes.overlay}>
          <CircularProgress size="5rem" />
        </div>
      )}
      {children}
    </div>
  );
};

LoadingOverlay.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

LoadingOverlay.defaultProps = {
  loading: false,
  children: null,
};

export default LoadingOverlay;
