import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

const styles = theme => ({
  root: {},
  fullWidth: {
    width: "100%",
  },
  formItem: {
    marginTop: theme.spacing(2),
  },
});

const EventEdit = ({
  open,
  handleClose,
  handleSave,
  classes,
  event,
  eventId,
}) => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [selectedDate, handleDateChange] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [seating, setSeating] = useState(null);
  const [seatingTableCounts, setSeatingTableCounts] = useState(null);
  const [seatingTableSeats, setSeatingTableSeats] = useState(null);
  const eDate = moment.unix(event.Date && event.Date.seconds).toDate();

  const save = () => {
    handleSave(
      eventId,
      title || event.Title,
      selectedDate || eDate,
      description || event.Description,
      shortName || event.ShortName,
      seating != null ? seating : event.Seating != null ? event.Seating : false,
      seatingTableCounts || event.SeatingTableCounts || 0,
      seatingTableSeats || event.SeatingTableSeats || 0,
    );
    setTitle(null);
    setDescription(null);
    handleDateChange(null);
    setShortName(null);
    setSeating(null);
    setSeatingTableCounts(null);
    setSeatingTableSeats(null);
  };

  const close = () => {
    handleClose();
    setTitle(null);
    setDescription(null);
    handleDateChange(null);
    setShortName(null);
    setSeating(null);
    setSeatingTableCounts(null);
    setSeatingTableSeats(null);
  };

  return (
    <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Event</DialogTitle>
      <DialogContent>
        <TextField
          //variant="outlined"
          id="title"
          label="Title"
          value={title || event.Title || ""}
          onChange={e => setTitle(e.target.value)}
          className={classes.formItem}
          fullWidth
        />
        <TextField
          //variant="outlined"
          id="shortName"
          label="ShortName"
          value={shortName || event.ShortName || ""}
          onChange={e => setShortName(e.target.value)}
          className={classes.formItem}
          fullWidth
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            //inputVariant="outlined"
            label="Date"
            className={classes.formItem}
            fullWidth
            value={selectedDate || eDate || Date.now()}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
        <TextField
          className={classes.formItem}
          id="description"
          label="Description"
          multiline
          rows={3}
          value={description || event.Description || ""}
          onChange={e => setDescription(e.target.value)}
          fullWidth
          //variant="outlined"
        />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  // TODO: Fix this as it always shows true
                  checked={
                    seating != null
                      ? seating
                      : event.Seating != null
                      ? event.Seating
                      : false
                  }
                  onChange={e => setSeating(e.target.checked)}
                />
              }
              label="Event Has Seating Assignments"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.formItem}
              id="seatingTableCount"
              label="# of Tables"
              type="number"
              disabled={!(seating || event.Seating || false)}
              value={seatingTableCounts || event.SeatingTableCounts || 0}
              onChange={e => setSeatingTableCounts(parseInt(e.target.value))}
              fullWidth
              //variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.formItem}
              disabled={!(seating || event.Seating || false)}
              id="seatingTableSeats"
              label="Seats Per Table"
              type="number"
              value={seatingTableSeats || event.SeatingTableSeats || 0}
              onChange={e => setSeatingTableSeats(parseInt(e.target.value))}
              fullWidth
              //variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={() => save()} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EventEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  eventId: PropTypes.string,
  event: PropTypes.shape({
    Date: PropTypes.objectOf(Date),
    Title: PropTypes.string,
    Description: PropTypes.string,
  }),
};

EventEdit.defaultProps = {
  event: {
    /*
    Date: Date.now(),
    Title: "",
    Description: "",
    */
  },
  eventId: "",
};

export default withStyles(styles)(EventEdit);
