/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Line from "../../svg/Line";

function Home() {
  return (
    <div>
      <a name="home">&nbsp;</a>
      <Header />
      <div className="body-container">
        <div>
          <h2>Jessica &amp; Jason</h2>
          <h5>are finally getting married</h5>
          <h1>TBD</h1>
          <h4>Charlotte | North Carolina</h4>
        </div>
        <div>
          <Line />
        </div>
        <div>
          <h4>an update about the wedding</h4>
          <p>
            This is an unprecedented and historic time that we currently find
            ourselves in. In the interest of the health of our friends and
            family, we are forced to change the plans for our wedding. Due to
            emergency declaration from the governor, our local county, and
            guidance from the CDC, we are not going to be able to hold the
            celebration of our marriage.
          </p>
          <p>
            We wanted to make sure that you had sufficient time to cancel any
            reservations or accommodations currently in place. We thank you for
            your understanding. While we were looking forward to spending our
            special day with you, we value your health and safety above all
            else.
          </p>
          <p>
            At this time we do not know when we will be able to celebrate our
            marriage. We will post updates here. Thank you all for your support
            through this. We are blessed to have you all beside us through this.
          </p>
        </div>
        <div>
          <Line />
        </div>
        <div>
          <a name="ourstory">
            <h2>Our Story</h2>
          </a>
          <div>
            <div className="imgContainer">
              <img
                alt="Jessica and Jason"
                src={process.env.PUBLIC_URL + "/img/us.png"}
              />
            </div>
            <div className="imgText">
              <p>
                After dating for 8 years, Jess and Jason are finally getting
                married. Following a few years of long distance dating
                they&apos;ve made Charlotte their new home.
              </p>
              <p>
                Last December, with a snowstorm quickly approaching, Jess and
                Jason went for a quick trip up to Asheville. Little did Jess
                know, at the top of a hill overlooking the Biltmore, Jason ask
                her to marry him.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
