import axios from "axios";
import {
  RSVP_LOGIN,
  RSVP_LOGIN_ERROR,
  RSVP_LOGIN_SUCCESS,
  RSVP_LOGIN_MULTIPLES,
  RSVP_GET,
  RSVP_GET_ERROR,
  RSVP_GET_SUCCESS,
  RSVP_POST,
  RSVP_POST_ERROR,
  RSVP_POST_SUCCESS,
  URL,
} from "./constants";

export const rsvpLogin = (
  lastName,
  zipCode,
  streetNumber,
) => async dispatch => {
  const data = { LastName: lastName, ZipCode: zipCode };
  if (streetNumber) {
    data["StreetNumber"] = streetNumber;
  }
  dispatch({
    type: RSVP_LOGIN,
    payload: {},
  });
  axios
    .post(`${URL}/login`, data)
    .then(res => {
      const key = res.data;
      // console.log(key);
      // setKey(res.data);
      dispatch({
        type: RSVP_LOGIN_SUCCESS,
        payload: key,
      });
      // dispatch(getRSVP());
    })
    .catch(error => {
      console.log(error);
      if (error && error.response && error.response.data) {
        const data = error.response.data;
        if (data.error && data.multiple) {
          // require street address
          dispatch({
            type: RSVP_LOGIN_MULTIPLES,
            payload: {},
          });
        } else {
          // set generic error
          dispatch({
            type: RSVP_LOGIN_ERROR,
            payload: data.errorMessage,
          });
        }
      } else {
        dispatch({
          type: RSVP_LOGIN_ERROR,
          payload: "An error has occured. Please try again later.",
          error: error,
        });
      }
      //console.log(res.data);
    });
};

export const getRSVP = () => async (dispatch, getState) => {
  dispatch({
    type: RSVP_GET,
    payload: {},
  });
  const key = getState().rsvp.key;
  axios
    .get(`${URL}/guest`, {
      headers: { Authorization: `Bearer ${key.token}` },
    })
    .then(res => {
      const data = res.data;
      // setKey(res.data);
      dispatch({
        type: RSVP_GET_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      console.log(error.response.data);
      const data = error.response.data;
      // set generic error
      dispatch({
        type: RSVP_GET_ERROR,
        payload: data.errorMessage,
      });
      //console.log(res.data);
    });
};

export const saveRSVP = guests => async (dispatch, getState) => {
  dispatch({
    type: RSVP_POST,
    payload: {},
  });
  const key = getState().rsvp.key;
  axios
    .put(`${URL}/guest`, guests, {
      headers: { Authorization: `Bearer ${key.token}` },
    })
    .then(res => {
      console.log(res);
      const data = res.data;
      // setKey(res.data);
      dispatch({
        type: RSVP_POST_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      console.log(error.response.data);
      const data = error.response.data;
      // set generic error
      dispatch({
        type: RSVP_POST_ERROR,
        payload: data.errorMessage,
      });
      //console.log(res.data);
    });
};

export const isLoggedIn = store => {
  return store.rsvp.key !== null;
};
