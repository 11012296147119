import React from "react";

function Line() {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1375.7 82.8"
    >
      <path
        className="st0"
        d="M1353.6 41.5c-24.5-.5-49.1-.7-73.7-.6l-34.7-.1-54.2-.2c-12.5 0-25 .2-37.4.3h-70.2c-12.2-.1-24.3-.4-36.5-.4l-43 .1h-31l-54.3-.1a27424.9 27424.9 0 0 0-154.5 0l-57.1.1-93.7.5c-45.2.3-90.5.3-135.7.3h-60l-84.2-.4-38.3.2-37.5.1-94.6.2-25.2.2-30.9-.3-37.4-.2-16.8-.3H21c-2.2-.1-3.8-.6-4-1.1-.3-.6.9-1.1 3-1.3l8.3-.2c16.2 0 32.3.3 48.5.7l29.9.1 42 .2 12.2-.2 89.9-.1c26.8 0 53.7-.4 80.5-.3l59.8.1c28 .4 56.2.3 84.3.3h72c12.9 0 25.7-.3 38.4-.1 12 0 23.7-.4 35.5-.3H656l25.2-.2H696l42.1-.2 66.5-.1h63.6c13.5 0 26.8 0 40.2.2l16-.1h118.8l30.9.2c20.9.3 41.8.2 62.7.2 12.2 0 24.3-.2 36.5-.3h46.6c10.5.6 21.2-.1 31.7.3h4.6c21.3-.2 42.5 0 63.7.1 9 0 18 .2 27 .4 2.9 0 5.7.2 7.2.8 1.2.6 1.2 1-.5 1.5zM46 25l20.4.3c2.5 0 5 .2 7.5 0 11.5-.4 23-.3 34.5 0 13.1.3 26.1.5 39.2.3 13.7-.2 27.5 0 41.2 0h23.4c24.9.2 49.9.3 74.8.7 20.3.4 40.5.4 60.8.4h22.5c17.4-.1 34.9-.3 52.4-.3l69.2-.2H623c11 .1 22 .5 32.8.3 12.2-.3 24.3-.1 36.5-.1 6.5 0 13 0 19.6.2 12.8.2 25.5 0 38.3-.1 19-.1 38 0 57 .1 26.8.2 53.6 0 80.5-.3 10.2-.1 20.6 0 30.8-.2 16.2-.3 32.4 0 48.6 0l56.1.3 32.7.2 39.3.2c16 0 31.8-.4 47.7-.4l30 .2 28 .2 52.4.1 16-.1a567 567 0 0 1 39.1.6c4.5.3 9.4.3 14 .2 6.3 0 12.4.1 18.6.3h10.2c3.6 0 6 .5 7.7 1.4 1 .4.3 1.1-1.6 1.3-3.6.4-7.3.6-11 .3-9.8-.8-19.7-.8-29.7-.7-2.8 0-5.7 0-8.4-.2-10.8-.8-21.7-.6-32.6-.6l-59 .1c-12.8 0-25.6 0-38.3-.2-11.3-.2-22.5-.2-33.7-.1l-74.9.3c-9.7 0-19.3-.3-29-.4l-63.7-.3c-11.8 0-23.7-.3-35.5-.2-20.3 0-40.6.2-60.8.3-25.3.1-50.5.6-75.8.1-15.9-.3-31.8 0-47.7 0l-78.7-.1-93.6-.1h-89l-74 .2-25.1.2H365l-23.4.1h-41.2l-31.8-.3-66.5-.5c-21.2-.2-42.4-.3-63.6.2-7.7.2-15.5-.1-23.3-.2-9-.1-18-.4-27.1-.4l-18.6.2h-29l-13.1.2c-3.7 0-5.8-.5-6-1.3-.2-1.2 2-2 5.8-2H46V25z"
      />
    </svg>
  );
}

export default Line;
