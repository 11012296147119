import React from "react";
import { FirebaseContext } from "../Firebase";
const withFirebase = Component => {
  class WithFirebase extends React.Component {
    render() {
      return (
        <FirebaseContext.Consumer>
          {firebase => <Component {...this.props} firebase={firebase} />}
        </FirebaseContext.Consumer>
      );
    }
  }
  //return withFirebase(WithAuthentication);
  return WithFirebase;
};
export default withFirebase;
