import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withFirebase } from "../../Firebase";
import { requestEvents } from "../../../actions/eventsActions";
import { requestGuests, getGuestCounts } from "../../../actions/guestsActions";
import { Grid } from "@material-ui/core";
import SeatingEventCard from "./SeatingEventCard";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingRight: theme.spacing(4),
  },
  timestamp: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  subHeader: {
    paddingBottom: theme.spacing(2),
  },
});

const Seating = ({
  events: eventsObj,
  guests: guestsObj,
  classes,
  eventCounts,
  firebase,
  dispatch,
  hideHeader,
}) => {
  const { events, timestamp } = eventsObj;
  const [lastUpdate, setLastUpdate] = useState(" ");

  useEffect(() => {
    dispatch(requestEvents(firebase));
    dispatch(requestGuests(firebase));
  }, [dispatch, firebase]);

  useEffect(() => {
    // update the lastUpdate field
    if (moment().to(timestamp) === "Invalid date") {
      setLastUpdate(" ");
    } else {
      setLastUpdate(`Last refreshed ${moment().to(timestamp)}`);
    }
    const timer = setInterval(() => {
      if (moment().to(timestamp) === "Invalid date") {
        setLastUpdate(" ");
      } else {
        setLastUpdate(`Last refreshed ${moment().to(timestamp)}`);
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, [timestamp]);

  // console.log(eventCounts);

  return (
    <div className={classes.container}>
      {!hideHeader && (
        <>
          <Typography className={classes.root} variant="h2">
            Seating
          </Typography>
          <div className={classes.timestamp}>
            <Typography
              // display="block"
              variant="caption"
            >
              {lastUpdate}
            </Typography>
          </div>
          <Typography className={classes.subHeader} variant="h5">
            Select the Event
          </Typography>
        </>
      )}
      <Grid container spacing={2}>
        {Object.entries(events || {}).map(([key, val]) => {
          console.log(key, val);
          if (val.Seating) {
            return (
              <Grid key={key} item xs={12} md={4}>
                <SeatingEventCard
                  eventName={val.Title}
                  eventId={key}
                  seated={eventCounts.seated[key]}
                  invited={eventCounts.invited[key]}
                />
              </Grid>
            );
          } else {
            return null;
          }
        })}
      </Grid>
    </div>
  );
};

Seating.defaultProps = {
  hideHeader: false,
};

const mapStateToProps = state => {
  const { events, guests } = state;
  return {
    events,
    eventCounts: getGuestCounts(state),
    guests,
  };
};

export default connect(mapStateToProps)(
  withFirebase(withStyles(styles)(Seating)),
);
