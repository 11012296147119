export const EVENTS_REQUEST = "events_request";
export const EVENTS_RETURNED = "events_returned";
export const EVENTS_ERROR = "events_error";
export const EVENTS_NEW = "events_new";
export const EVENTS_UPDATE = "events_update";

export const GUESTS_REQUEST = "guests_request";
export const GUESTS_RETURNED = "guests_returned";

export const RSVP_LOGIN = "rsvp_login";
export const RSVP_LOGIN_SUCCESS = "rsvp_login_success";
export const RSVP_LOGIN_ERROR = "rsvp_login_error";
export const RSVP_LOGIN_MULTIPLES = "rsvp_login_multiples";
export const RSVP_GET = "rsvp_get";
export const RSVP_GET_SUCCESS = "rsvp_get_success";
export const RSVP_GET_ERROR = "rsvp_get_error";
export const RSVP_POST = "rsvp_post";
export const RSVP_POST_SUCCESS = "rsvp_post_success";
export const RSVP_POST_ERROR = "rsvp_post_error";

// export const URL = "https://us-central1-wedding-257501.cloudfunctions.net";
let thisURL = "https://us-central1-wedding-257501.cloudfunctions.net";
if (process.env.NODE_ENV !== "production") {
  thisURL = "http://localhost:5000/wedding-257501/us-central1";
}

export const URL = thisURL;
