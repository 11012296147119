import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
      minHeight: theme.spacing(14),
    },
  };
});

const RSVPPhotos = () => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Typography variant="subtitle2">
          We will be displaying photos of our friend&apos;s and family&apos;s
          weddings at the reception. If you have a photo that you would like to
          share with us, please email them to{" "}
          <a
            href="mailto:jdominiczak@gmail.com?subject=WeddingPictures"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jason.
          </a>
        </Typography>
      </Grid>
    </>
  );
};

export default RSVPPhotos;
