import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import RSVP from "./components/RSVP";
import Admin from "./components/Admin";
import Login from "./components/Admin/Login";
// import { FirebaseContext } from "./components/Firebase";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin/login">
          <Login />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/rsvp">
          <RSVP />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
