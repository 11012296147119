import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, withRouter, Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import AddressDialog from "./AddressDialog";
import GuestEditTable from "./GuestEditTable";
import { updateGuest, requestGuests } from "../../../actions/guestsActions";
import { requestEvents } from "../../../actions/eventsActions";
import { withFirebase } from "../../Firebase";
import LoadingOverlay from "../../ui/LoadingOverlay";
import ErrorBoundary from "../../ui/ErrorBoundary";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  paper: {
    margin: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(2),
  },
  title: {
    display: "inline",
    verticalAlign: "middle",
    paddingLeft: theme.spacing(2),
  },
  buttons: {
    textAlign: "right",
    paddingTop: theme.spacing(2),
  },
  addressRadio: {
    display: "inline",
    verticalAlign: "text-bottom",
  },
  addressText: {
    display: "inline-block",
  },
  subTitle: {
    paddingBottom: theme.spacing(2),
  },
  pullRight: {
    float: "right",
  },
});

const GuestEdit = ({
  classes,
  guestsObj,
  history,
  dispatch,
  firebase,
  events,
}) => {
  const [guest, setGuest] = useState({});
  const [addressInEdit, setAddressInEdit] = useState(null);

  let { id } = useParams();
  useEffect(() => {
    dispatch(requestGuests(firebase));
    dispatch(requestEvents(firebase));
  }, [dispatch, firebase]);

  useEffect(() => {
    if (id === "new") {
      setGuest(
        guestsObj && guestsObj.guests && guestsObj.guests[id]
          ? guestsObj.guests[id]
          : { id: "new" },
      );
    } else {
      setGuest(
        guestsObj && guestsObj.guests && guestsObj.guests[id]
          ? guestsObj.guests[id]
          : {},
      );
    }
  }, [id, guestsObj]);

  const updateField = e => {
    setGuest({ ...guest, [e.target.id]: e.target.value });
  };

  const updateGuests = guests => {
    setGuest({ ...guest, Guests: guests });
  };

  const saveGuest = () => {
    dispatch(updateGuest(firebase, id, guest));
    history.push("/admin/guests");
  };

  const setPrimaryAddress = id => {
    const newGuest = { ...guest };
    newGuest.Addresses = newGuest.Addresses.map((address, index) => {
      if (index === id) {
        return { ...address, Primary: true };
      } else {
        return { ...address, Primary: false };
      }
    });
    setGuest(newGuest);
  };

  const deleteAddress = id => {
    const newGuest = { ...guest };
    newGuest.Addresses = newGuest.Addresses.filter((val, index) => {
      return index !== id;
    });
    setGuest(newGuest);
  };

  const editAddress = id => {
    setAddressInEdit(id);
  };

  const saveAddress = address => {
    let newAddresses = guest.Addresses;
    if (!newAddresses) {
      newAddresses = [];
    }
    if (addressInEdit === "new") {
      newAddresses.push(address);
    } else {
      newAddresses[addressInEdit] = address;
    }
    setGuest({ ...guest, Addresses: newAddresses });
    setAddressInEdit(null);
  };

  return (
    <>
      <AddressDialog
        open={addressInEdit !== null}
        handleClose={() => setAddressInEdit(null)}
        handleSave={address => saveAddress(address)}
        address={
          guest && guest.Addresses && guest.Addresses[addressInEdit]
            ? guest.Addresses[addressInEdit]
            : {}
        }
      />
      <div>
        <LoadingOverlay className={classes.paper} loading={guestsObj.loading}>
          <Paper>
            <div>
              <IconButton component={Link} to="/admin/guests">
                <ArrowBackIcon />
              </IconButton>
              <Typography className={classes.title} variant="h5">
                Edit Guest
              </Typography>
            </div>
            <Grid className={classes.body} container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Nickname"
                  id="ShortName"
                  fullWidth
                  value={guest.ShortName || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={updateField}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Addresses"
                  id="ShortName"
                  disabled
                  fullWidth
                  value={(guest.Emails || []).join(",")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={updateField}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle} variant="h6">
                  Guests
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ErrorBoundary>
                  <GuestEditTable
                    guests={guest.Guests || []}
                    events={events}
                    updateGuests={updateGuests}
                  />
                </ErrorBoundary>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.subTitle} variant="h6">
                  Changes
                </Typography>
              </Grid>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="Change Table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Time</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(guest.Changes || []).map((change, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {moment(change.timestamp).format(
                              "YYYY-MM-DD HH:mm:ss",
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {change.description}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={12}>
                <IconButton
                  className={classes.pullRight}
                  onClick={() => setAddressInEdit("new")}
                >
                  <AddIcon />
                </IconButton>
                <Typography className={classes.subTitle} variant="h6">
                  Addresses
                </Typography>
              </Grid>
              {(guest.Addresses || []).map((address, index) => {
                return (
                  <Grid key={index} item xs={12} md={6} lg={4}>
                    <Paper className={classes.paper}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={address.Primary || false}
                            onChange={() => setPrimaryAddress(index)}
                            value="a"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "A" }}
                          />
                        }
                        label="Primary Address"
                      />
                      <Typography>{address.Address1 || ""}</Typography>
                      <Typography>{address.Address2 || ""}</Typography>
                      <Typography>
                        {`${address.City || ""}, ${address.State ||
                          ""} ${address.Zip || ""}`}
                      </Typography>
                      <div className={classes.buttons}>
                        <Button
                          color="secondary"
                          onClick={e => {
                            deleteAddress(index);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => editAddress(index)}
                        >
                          Edit
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <div className={classes.buttons}>
              <Button color="secondary">Delete</Button>
              <Button color="primary" onClick={saveGuest}>
                Save
              </Button>
            </div>
          </Paper>
        </LoadingOverlay>
      </div>
    </>
  );
};

GuestEdit.propTypes = {};

const mapStateToProps = state => {
  const { events, guests } = state;
  return {
    events,
    guestsObj: guests,
  };
};

export default withFirebase(
  withRouter(connect(mapStateToProps)(withStyles(styles)(GuestEdit))),
);
