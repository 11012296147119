import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { TextField, Grid, Button, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
    },
    emailBox: {
      maxWidth: "400px",
    },
  };
});

const RSVPContact = ({ emails, handleUpdate }) => {
  const classes = useStyles();
  const updateEmail = (index, val) => {
    const newEmails = [...emails];
    newEmails[index] = val;
    handleUpdate(newEmails);
  };

  const deleteEmail = index => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    handleUpdate(newEmails);
  };

  return (
    <>
      <Typography variant="subtitle2">
        We are looking forward to spending our special day with you. <br />
        Please leave us an email address so we can keep in touch.
      </Typography>
      <Grid container className={classes.root} spacing={2}>
        {(emails.length > 0 ? emails : [""]).map((email, index) => (
          <Grid item key={index} xs={12}>
            <TextField
              className={classes.emailBox}
              fullWidth
              value={email}
              placeholder="sample@email.com"
              onChange={e => updateEmail(index, e.target.value)}
            />
            <IconButton aria-label="delete" onClick={() => deleteEmail(index)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button onClick={() => updateEmail(emails.length, "")}>
            Add Additional Email
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default RSVPContact;
