import React from "react";
import { Switch, Route } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import NavBar from "./NavBar";
import Guests from "./Guests";
import Events from "./Events";
import Seating from "./Seating";
import SeatingEdit from "./Seating/SeatingEdit";
import Dashboard from "./Dashboard/Dashboard";
import { withAuthorization } from "../Session";

const Admin = props => {
  return (
    <NavBar>
      <Switch>
        <Route path="/admin/guests">
          <Guests />
        </Route>
        <Route path="/admin/events">
          <Events />
        </Route>
        <Route path="/admin/seating/:id">
          <SeatingEdit />
        </Route>
        <Route path="/admin/seating">
          <Seating />
        </Route>
        <Route path="/admin/thankyou">
          <Typography variant="h2">Thank Yous</Typography>
        </Route>
        <Route path="/admin">
          <Dashboard />
        </Route>
      </Switch>
    </NavBar>
  );
};

Admin.propTypes = {};

export default withAuthorization()(Admin);
