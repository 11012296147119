import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { withFirebase } from "../Firebase";
import TopSVG from "../../svg/TopSVG";
import BottomSVG from "../../svg/BottomSVG";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  formLogin: {
    padding: theme.spacing(2),
  },
  h2Text: {
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: 0,
  },
}));

const Login = ({ firebase, history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onSubmit = event => {
    console.log(firebase);
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setEmail("");
        setPassword("");
        history.push("/admin");
      })
      .catch(error => {
        setError(error.message);
      });
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={false} sm={1} md={3} lg={4} xl={5} />
      <Grid item xs={12} sm={10} md={6} lg={4} xl={2}>
        <Paper className={classes.formLogin}>
          <TopSVG />
          <Typography className={classes.h2Text} variant="h4">
            Admin Log In
          </Typography>
          {error !== "" && (
            <Typography color="error" variant="subtitle1">
              {error}
            </Typography>
          )}
          <TextField
            id="username-input"
            label="Username"
            className={classes.fullWidth}
            autoComplete="current-username"
            margin="normal"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            id="password-input"
            label="Password"
            className={classes.fullWidth}
            type="password"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            value={password}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onSubmit();
              }
            }}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onSubmit}
          >
            Log In
          </Button>
          <BottomSVG />
        </Paper>
      </Grid>
      <Grid item xs={false} sm={1} md={3} lg={4} xl={5} />
    </Grid>
  );
};

export default withFirebase(withRouter(Login));
