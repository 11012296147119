import React from 'react';
import BottomSVG from "../../svg/BottomSVG";

const Footer = () => {
  return (
    <div className="footer-container">
      <BottomSVG />
    </div>
  );
};

export default Footer;