// import omit from "omit-deep-lodash";
// const omit = require("lodash/omit");
import omit from "lodash/omit";

export const unFirebaseObject = obj => {
  return omitDeepLodash(obj, [
    "firestore",
    "FirebaseAppImpl",
    "_firestoreClient",
  ]);
};

function omitDeepLodash(input, props) {
  function omitDeepOnOwnProps(obj) {
    if (typeof input === "undefined") {
      return input;
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return omitDeepLodash(obj, props);
    }

    const o = {};
    for (const [key, value] of Object.entries(obj)) {
      // console.log(key);
      if (!props.includes(key)) {
        o[key] = !isNil(value) ? omitDeepLodash(value, props) : value;
      }
    }

    return omit(o, props);
  }

  if (arguments.length > 2) {
    props = Array.prototype.slice.call(arguments).slice(1);
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps);
  }

  return omitDeepOnOwnProps(input);
}

function isNil(value) {
  return value === null || value === undefined;
}

function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
