import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Paper from "@material-ui/core/Paper";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import remove from "lodash.remove";
import LoadingOverlay from "../ui/LoadingOverlay";
import RSVPGuest from "./RSVPGuest";
import RSVPEvent from "./RSVPEvent";
import RSVPPhotos from "./RSVPPhotos";
import RSVPContact from "./RSVPContact";
import RSVPTranspo from "./RSVPTranspo";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Contact", "Guests", "Events", "Transportation", "Photos"];
}

const RSVPForm = ({ rsvp, loading, error, doSave }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [newRSVP, setRSVP] = useState(
    (rsvp && rsvp.guest) || { Emails: [], Guests: [] },
  );

  useEffect(() => {
    if (rsvp && rsvp.guest) {
      setRSVP(rsvp.guest);
    }
  }, [rsvp]); // Only re-run the effect if count changes

  const updateEmails = list => {
    const updated = { ...newRSVP };
    updated.Emails = list;
    setRSVP(updated);
  };

  const updateSeats = number => {
    const updated = { ...newRSVP };
    updated.BusSeats = number;
    setRSVP(updated);
  };

  const updateGuest = (index, field, value) => {
    //setRSVP(newRSVP.Guests.guest)
    // console.log("Updating", index, field, value);
    const updated = { ...newRSVP };
    updated.Guests[index][field] = value;
    // console.log(updated);
    setRSVP(updated);
  };

  const updateRSVP = (guestIndex, eventId, attending) => {
    const update = {
      ...newRSVP,
    };
    // remove both an attending and decline entry to prepare
    let att = update.Guests[guestIndex].Attending || [];
    let notAtt = update.Guests[guestIndex].NotAttending || [];
    // remove both an attending and decline entry to prepare
    att = remove(att, val => {
      if (val.segments && val.segments[val.segments.length - 1] === eventId) {
        return false;
      }
      return true;
    });
    notAtt = remove(notAtt, val => {
      if (val.segments && val.segments[val.segments.length - 1] === eventId) {
        return false;
      }
      return true;
    });

    if (attending === "attending") {
      att.push({
        segments: ["events", eventId],
      });
    } else {
      // declined
      notAtt.push({
        segments: ["events", eventId],
      });
    }
    update.Guests[guestIndex].Attending = att;
    update.Guests[guestIndex].NotAttending = notAtt;
    setRSVP(update);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSave = () => {
    doSave(newRSVP);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <RSVPContact
            emails={newRSVP.Emails || []}
            handleUpdate={updateEmails}
          />
        );
      case 1:
        return <RSVPGuest guests={newRSVP} handleUpdate={updateGuest} />;
      case 2:
        return (
          <RSVPEvent
            guests={newRSVP}
            events={rsvp.events}
            handleUpdate={updateRSVP}
          />
        );
      case 3:
        return (
          <RSVPTranspo
            busSeats={newRSVP.BusSeats}
            handleUpdate={updateSeats}
            guests={newRSVP}
          />
        );
      case 4:
        return <RSVPPhotos />;
      default:
        return "Unknown step";
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <LoadingOverlay loading={loading}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography variant="body1">
                  All steps completed - Thank you for your reply.
                </Typography>
                <Typography variant="body1">
                  <a href="/">Return Home</a>
                </Typography>
              </div>
            ) : (
              <div>
                {getStepContent(activeStep)}
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      activeStep === steps.length - 1
                        ? () => {
                            handleSave();
                            handleNext();
                          }
                        : handleNext
                    }
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </LoadingOverlay>
      </Paper>
    </div>
  );
};

RSVPForm.propTypes = {
  rsvp: PropTypes.shape({
    Guests: PropTypes.shape({}),
    Events: PropTypes.shape({}),
  }),
};

RSVPForm.defaultProps = {
  rsvp: {},
};

export default RSVPForm;
