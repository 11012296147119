import moment from "moment";
import { EVENTS_REQUEST, EVENTS_RETURNED } from "./constants";

export const requestEvents = firebase => async dispatch => {
  dispatch({
    type: EVENTS_REQUEST,
    payload: {},
  });
  firebase.getEvents().then(querySnapshot => {
    const eventsList = {};
    querySnapshot.forEach(doc => {
      eventsList[doc.id] = doc.data();
    });
    dispatch({
      type: EVENTS_RETURNED,
      payload: eventsList,
    });
  });
};

export const removeEvent = (firebase, id) => async dispatch => {
  if (id === "new") {
    return;
  } else {
    firebase
      .updateEvent(id)
      .delete()
      .then(() => dispatch(requestEvents(firebase)));
  }
};

export const updateEvent = (
  firebase,
  id,
  title,
  description,
  date,
  shortName,
  seating,
  seatingTableCounts,
  seatingTableSeats,
) => async dispatch => {
  if (id === "new") {
    firebase
      .addEvent()
      .set({
        Title: title,
        Description: description,
        Date: { seconds: moment(date).unix(), microseconds: 0 },
        ShortName: shortName,
        Seating: seating,
        SeatingTableCounts: seatingTableCounts,
        SeatingTableSeats: seatingTableSeats,
      })
      .then(() => dispatch(requestEvents(firebase)));
  } else {
    firebase
      .updateEvent(id)
      .set({
        Title: title,
        Description: description,
        Date: { seconds: moment(date).unix(), microseconds: 0 },
        ShortName: shortName,
        Seating: seating,
        SeatingTableCounts: seatingTableCounts,
        SeatingTableSeats: seatingTableSeats,
      })
      .then(() => dispatch(requestEvents(firebase)));
  }
};
