import {
  RSVP_LOGIN,
  RSVP_LOGIN_ERROR,
  RSVP_LOGIN_SUCCESS,
  RSVP_LOGIN_MULTIPLES,
  RSVP_GET,
  RSVP_GET_SUCCESS,
  RSVP_GET_ERROR,
} from "../actions/constants";

export default (
  state = {
    key: null,
    loginLoading: false,
    loginError: null,
    loginMultiples: false,
    rsvpLoading: false,
    rsvp: {},
    rsvpError: null,
  },
  action,
) => {
  if (action.type === RSVP_LOGIN) {
    return Object.assign({}, state, {
      loginLoading: true,
      loginError: null,
      loginMultiples: false,
    });
  }
  if (action.type === RSVP_LOGIN_SUCCESS) {
    return Object.assign({}, state, {
      key: action.payload,
      loginLoading: false,
      loginError: null,
      loginMultiples: false,
    });
  }
  if (action.type === RSVP_LOGIN_ERROR) {
    return Object.assign({}, state, {
      key: null,
      loginLoading: false,
      loginError: action.payload,
      loginMultiples: false,
    });
  }
  if (action.type === RSVP_LOGIN_MULTIPLES) {
    return Object.assign({}, state, {
      key: null,
      loginLoading: false,
      loginError: null,
      loginMultiples: true,
    });
  }
  /*
  if (action.type === RSVP_POST) {
    return Object.assign({}, state, {
      rsvp: {},
      rsvploading: true,
      rsvperror: null,
    });
  }
  if (action.type === RSVP_POST_SUCCESS) {
    return Object.assign({}, state, {
      rsvp: action.payload,
      rsvploading: false,
      rsvperror: null,
    });
  }
  if (action.type === RSVP_POST_ERROR) {
    return Object.assign({}, state, {
      rsvp: {},
      rsvploading: false,
      rsvperror: action.payload,
    });
  }
  */
  if (action.type === RSVP_GET) {
    return Object.assign({}, state, {
      rsvpLoading: true,
      rsvpError: null,
      rsvp: null,
    });
  }
  if (action.type === RSVP_GET_SUCCESS) {
    return Object.assign({}, state, {
      rsvpLoading: false,
      rsvpError: null,
      rsvp: action.payload,
    });
  }
  if (action.type === RSVP_GET_ERROR) {
    return Object.assign({}, state, {
      rsvpLoading: false,
      rsvpError: action.payload,
      rsvp: null,
    });
  }
  return state;
};
