import React from "react";
import Typography from "@material-ui/core/Typography";
import Event from "../Events";

const Dashboard = props => {
  return (
    <>
      <Typography variant="h2">Dashboard</Typography>
      <Typography variant="h4">Events</Typography>
      <Event hideHeader />
    </>
  );
};

export default Dashboard;
