import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import tableIcons from "../../ui/tableIcons";

const GuestTable = ({
  loading,
  handleDelete,
  handleAdd,
  handleEdit,
  guests,
}) => {
  return (
    <MaterialTable
      isLoading={loading}
      icons={tableIcons}
      style={{
        marginRight: 24,
      }}
      options={{
        actionsColumnIndex: 4,
        paging: false,
      }}
      onRowClick={(event, row) => {
        handleEdit(row.id);
      }}
      actions={[
        {
          // eslint-disable-next-line react/display-name
          icon: () => <DeleteIcon />,
          tooltip: "Delete",
          onClick: (event, rowData) => {
            handleDelete(rowData);
          },
        },
        {
          // eslint-disable-next-line react/display-name
          icon: () => <AddIcon />,
          tooltip: "New",
          isFreeAction: true,
          onClick: () => {
            handleAdd();
          },
        },
      ]}
      columns={[
        { title: "Name", field: "ShortName" },
        {
          title: "# Guests",
          field: "Guests",
          render: row => {
            return (row.Guests || []).length;
          },
        },
        {
          title: "# Bus",
          field: "BusSeats",
          render: row => {
            return row.BusSeats || "";
          },
        },
        {
          title: "RSVP'ed",
          field: "RSVP",
          render: row => {
            let invEvents = 0;
            let rsvpedEvents = 0;
            (row.Guests || []).forEach(guest => {
              invEvents += (guest.InvitedTo || []).length;
              rsvpedEvents += (guest.Attending || []).length;
              rsvpedEvents += (guest.NotAttending || []).length;
            });
            if (invEvents === 0) {
              return "-";
            }
            if (invEvents === rsvpedEvents) {
              return "Yes";
            } else if (rsvpedEvents !== 0) {
              return "Partial";
            } else {
              return "No";
            }
          },
        },
        {
          title: "Guests",
          field: "Guests",
          render: rowData => {
            let names = "";
            let plusOnes = 0;
            (rowData.Guests || []).forEach(g => {
              if (g.PlusOne) {
                plusOnes++;
              } else {
                names = `${names}${g.FirstName} `;
              }
            });
            if (plusOnes > 0) {
              return `${names} +${plusOnes}`;
            } else {
              return names;
            }
          },
        },
      ]}
      data={guests}
      title="Guest List"
    />
  );
};

GuestTable.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  guests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
};

GuestTable.defaultProps = {
  loading: false,
  guests: [],
};

export default GuestTable;
