import { GUESTS_REQUEST, GUESTS_RETURNED } from "./constants";

export const requestGuests = firebase => async dispatch => {
  dispatch({
    type: GUESTS_REQUEST,
    payload: {},
  });
  firebase.getGuests().then(querySnapshot => {
    const guestsList = {};
    querySnapshot.forEach(doc => {
      guestsList[doc.id] = doc.data();
      // console.log(JSON.stringify(doc.data()));
    });
    dispatch({
      type: GUESTS_RETURNED,
      payload: guestsList,
    });
  });
};

export const getGuestCounts = state => {
  const { guests } = state.guests;
  let count = {
    invited: {},
    attending: {},
    notAttending: {},
    noResponse: {},
    seated: {},
  };
  // console.log(Object.values(guests));
  Object.values(guests || {}).forEach(inv => {
    // console.log(inv);
    (inv.Guests || []).forEach(guest => {
      (guest.InvitedTo || []).forEach(e => {
        let invited = false;
        let attending = false;
        let notAttending = false;
        if (e.parent.id && e.parent.id === "events") {
          invited = true;
          if (count.invited[e.id]) {
            count.invited[e.id] += 1;
          } else {
            count.invited[e.id] = 1;
          }
          // is there a seating assignment for this person
          Object.entries(guest.TableAssignment || {}).forEach(([id, table]) => {
            if (id === e.id && table !== null) {
              if (count.seated[e.id]) {
                count.seated[e.id] += 1;
              } else {
                count.seated[e.id] = 1;
              }
            }
          });
          // now that we know this person is invited, we need to see if they are attending, not attending, or no response yet
          (guest.Attending || []).forEach(att => {
            if (
              att.parent &&
              att.parent.id &&
              att.parent.id === "events" &&
              att.id === e.id
            ) {
              attending = true;
              if (count.attending[e.id]) {
                count.attending[e.id] += 1;
              } else {
                count.attending[e.id] = 1;
              }
            }
          });
          (guest.NotAttending || []).forEach(att => {
            if (
              att.parent &&
              att.parent.id &&
              att.parent.id === "events" &&
              att.id === e.id
            ) {
              notAttending = true;
              if (count.notAttending[e.id]) {
                count.notAttending[e.id] += 1;
              } else {
                count.notAttending[e.id] = 1;
              }
            }
          });
          if (invited && !attending && !notAttending) {
            if (count.noResponse[e.id]) {
              count.noResponse[e.id] += 1;
            } else {
              count.noResponse[e.id] = 1;
            }
          }
        }
      });
    });
  });
  return count;
};

export const updateGuest = (firebase, id, guest) => async dispatch => {
  const newGuest = { ...guest };
  delete newGuest.id;
  console.log(newGuest);
  if (id === "new") {
    firebase
      .addGuest()
      .set(newGuest)
      .then(() => dispatch(requestGuests(firebase)));
  } else {
    firebase
      .updateGuest(id)
      .set(newGuest)
      .then(() => dispatch(requestGuests(firebase)));
  }
};
