import React from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase";

/*
 *  If you don't pass condition, it will be set to require authentication
 */
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      if (condition === undefined) {
        condition = authUser => !!authUser;
      }
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (!condition(authUser)) {
          this.props.history.push("/admin/login");
        }
      });
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      return <Component {...this.props} />;
    }
  }
  return withFirebase(withRouter(WithAuthorization));
};
export default withAuthorization;
