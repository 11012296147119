import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
    },
  };
});

const GuestRow = ({ index, eventId, guest, handleUpdate }) => {
  let invited = false;
  let attending = false;
  let declined = false;
  // console.log(eventId);
  (guest.InvitedTo || []).forEach(inv => {
    // console.log(inv);
    if (inv.segments) {
      const thisId = inv.segments[inv.segments.length - 1];
      if (thisId === eventId) {
        // console.log(thisId, eventId);
        invited = true;
      }
    }
  });
  (guest.Attending || []).forEach(inv => {
    // console.log(inv);
    if (inv.segments) {
      const thisId = inv.segments[inv.segments.length - 1];
      if (thisId === eventId) {
        // console.log(thisId, eventId);
        attending = true;
      }
    }
  });
  (guest.NotAttending || []).forEach(inv => {
    // console.log(inv);
    if (inv.segments) {
      const thisId = inv.segments[inv.segments.length - 1];
      if (thisId === eventId) {
        // console.log(thisId, eventId);
        declined = true;
      }
    }
  });
  if (invited) {
    // console.log(attending, declined, invited);
    return (
      <>
        <Grid item xs={6}>
          <TextField
            value={`${guest.FirstName} ${guest.LastName}`}
            label="Guest"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink id="event-label-label">
              Response
            </InputLabel>
            <Select
              labelId="event-label-label"
              id="event-placeholder-label"
              value={attending ? "attending" : declined ? "decline" : ""}
              // value={age}
              onChange={e => {
                handleUpdate(index, eventId, e.target.value);
              }}
              // onChange={handleChange}
              displayEmpty
              fullWidth
              // className={classes.selectEmpty}
            >
              <MenuItem disabled value="">
                <em>RSVP</em>
              </MenuItem>
              <MenuItem value={"attending"}>Joyfully Accept</MenuItem>
              <MenuItem value={"decline"}>Regretfully Decline</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  }
  return null;
};

const EventRow = ({ index, event, guests, handleUpdate }) => {
  const classes = useStyles();
  const displayDate = moment
    .unix(event.Date.seconds)
    .format("h:mma dddd MMMM Do, YYYY");

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">{event.Title}</Typography>
        <Typography variant="subtitle1">{displayDate}</Typography>
        <Typography variant="body2">{event.Description}</Typography>
        <Grid className={classes.root} container spacing={2}>
          {(guests.Guests || []).map((guest, index) => (
            <GuestRow
              guest={guest}
              key={index}
              index={index}
              eventId={event.id}
              handleUpdate={handleUpdate}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <hr />
      </Grid>
    </>
  );
};

const sortByDatetime = (a, b) => {
  if (a && a.Date && b && b.Date) {
    if (a.Date.seconds > b.Date.seconds) {
      return 1;
    } else {
      return -1;
    }
  }
  return 0;
};

const RSVPEvent = ({ guests, events, handleUpdate }) => {
  console.log(guests);
  console.log(events);
  return (
    <div>
      <Typography variant="subtitle1">
        Please let us know if you are able to attend. We look forward to
        celebrating with you!
      </Typography>
      <hr />
      <Grid container spacing={2}>
        {(events.sort((a, b) => sortByDatetime(a, b)) || []).map(
          (event, index) => (
            <EventRow
              key={index}
              index={index}
              event={event}
              guests={guests}
              /*
            firstName={guest.FirstName}
            lastName={guest.LastName}
            allergies={guest.Allergies}
            plusOne={guest.PlusOne}
            */
              handleUpdate={handleUpdate}
            />
          ),
        )}
      </Grid>
    </div>
  );
};

export default RSVPEvent;
