import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../Header";
import Footer from "../Footer";
import LoginForm from "./LoginForm";
import {
  rsvpLogin,
  isLoggedIn,
  getRSVP,
  saveRSVP,
} from "../../actions/rsvpActions";
import RSVPForm from "./RSVPForm";
//import { Typography } from "@material-ui/core";

const RSVP = ({
  requestRSVP,
  doLogin,
  loginLoading,
  loginError,
  multiples,
  loginErrorMessage,
  loggedIn,
  rsvp,
  rsvpLoading,
  rsvpError,
  submitRSVP,
}) => {
  /*
  const [key, setKey] = useState(sessionStorage.getItem("key") || null);
  const [reservation, setReservation] = useState(null);
  */
  useEffect(() => {
    if (loggedIn) {
      requestRSVP();
    }
  }, [requestRSVP, loggedIn]);

  return (
    <>
      <Header />
      <div className="body-container">
        {!loggedIn ? (
          <LoginForm
            doLogin={doLogin}
            error={loginError}
            multiples={multiples}
            errorMessage={loginErrorMessage}
            loading={loginLoading}
          />
        ) : (
          <RSVPForm
            rsvp={rsvp}
            loading={rsvpLoading}
            error={rsvpError}
            doSave={submitRSVP}
          />
        )}
        {/*
        <Typography variant="h6">RSVP will open up shortly.</Typography>
        <Typography variant="h6">Please check back soon.</Typography>
        */}
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = state => {
  const { rsvp } = state;
  return {
    loginError: rsvp.loginError !== null,
    loginLoading: rsvp.loginLoading,
    multiples: rsvp.loginMultiples,
    loginErrorMessage: rsvp.loginError,
    loggedIn: isLoggedIn(state),
    rsvp: rsvp.rsvp,
    rsvpLoading: rsvp.rsvpLoading,
    rsvpError: rsvp.rsvpError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // explicitly forwarding arguments
    doLogin: (lastName, zipCode, streetNumber) =>
      dispatch(rsvpLogin(lastName, zipCode, streetNumber)),
    requestRSVP: () => dispatch(getRSVP()),
    submitRSVP: guests => dispatch(saveRSVP(guests)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSVP);
