import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withFirebase } from "../../Firebase";
import EventCard from "./EventCard";
import EventEdit from "./EventEdit";
import {
  requestEvents,
  updateEvent,
  removeEvent,
} from "../../../actions/eventsActions";
import { requestGuests, getGuestCounts } from "../../../actions/guestsActions";
import ConfirmDialog from "../../ui/ConfirmDialog";

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  timestamp: {
    paddingLeft: theme.spacing(4),
    // paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fab: {
    margin: 0,
    top: "auto",
    left: "auto",
    bottom: 20,
    right: 20,
    position: "fixed",
  },
});

const Events = ({
  events: eventsObj,
  classes,
  eventCounts,
  firebase,
  dispatch,
  hideHeader,
}) => {
  const [editId, setEditId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(" ");
  const closeEdit = () => {
    setEditId(null);
  };
  useEffect(() => {
    dispatch(requestEvents(firebase));
    dispatch(requestGuests(firebase));
  }, [dispatch, firebase]);
  useEffect(() => {
    // update the lastUpdate field
    if (moment().to(eventsObj.timestamp) === "Invalid date") {
      setLastUpdate(" ");
    } else {
      setLastUpdate(`Last Refreshed ${moment().to(eventsObj.timestamp)}`);
    }
    const timer = setInterval(() => {
      if (moment().to(eventsObj.timestamp) === "Invalid date") {
        setLastUpdate(" ");
      } else {
        setLastUpdate(`Last Refreshed ${moment().to(eventsObj.timestamp)}`);
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, [eventsObj.timestamp]);
  const openEdit = id => {
    setEditId(id);
  };

  const newEvent = () => {
    setEditId("new");
  };

  const saveEvent = (
    id,
    title,
    date,
    description,
    shortName,
    seating,
    seatingTableCounts,
    seatingTableSeats,
  ) => {
    dispatch(
      updateEvent(
        firebase,
        id,
        title,
        description,
        date,
        shortName,
        seating,
        seatingTableCounts,
        seatingTableSeats,
      ),
    );
    closeEdit();
  };

  const confirmDeleteEvent = id => {
    setConfirmOpen(id);
    // dispatch(removeEvent(firebase, id));
  };

  const deleteEvent = () => {
    dispatch(removeEvent(firebase, confirmOpen));
    setConfirmOpen(null);
  };

  // console.log(props);
  const { loading, events, error } = eventsObj;
  return (
    <>
      <ConfirmDialog
        open={confirmOpen !== null}
        title="Delete this event?"
        content="Do you want to delete this event record?  This action can not be undone."
        yesClick={() => deleteEvent()}
        handleClose={() => setConfirmOpen(false)}
      />
      <EventEdit
        open={editId !== null}
        eventId={editId || undefined}
        event={events[editId]}
        handleClose={closeEdit}
        handleSave={saveEvent}
      />
      {!hideHeader && (
        <>
          <Typography className={classes.root} variant="h2">
            Events
          </Typography>
          <div className={classes.timestamp}>
            <Typography
              // display="block"
              variant="caption"
            >
              {lastUpdate}
            </Typography>
          </div>
        </>
      )}
      {loading && <Typography variant="body1">Loading</Typography>}
      {error && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        {Object.entries(events).map(([key, event]) => {
          return (
            <Grid key={key} item sm={12} md={6} lg={3}>
              <EventCard
                embedded={hideHeader}
                title={event.Title}
                description={event.Description}
                date={event.Date}
                id={key}
                shortName={event.ShortName}
                deleteEvent={key => confirmDeleteEvent(key)}
                editEvent={key => openEdit(key)}
                attending={eventCounts.attending[key]}
                invited={eventCounts.invited[key]}
                noResponse={eventCounts.noResponse[key]}
                seating={event.Seating}
                seatingTableCounts={event.SeatingTableCounts}
                seatingTableSeats={event.SeatingTableSeats}
              />
            </Grid>
          );
        })}
      </Grid>
      {!hideHeader && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={newEvent}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      )}
    </>
  );
};

Events.defaultProps = {
  hideHeader: false,
};

const mapStateToProps = state => {
  const { events } = state;
  return {
    events,
    eventCounts: getGuestCounts(state),
    // timeDiff: getTimeSinceLastQuery(events.timestamp),
  };
};

export default connect(mapStateToProps)(
  withFirebase(withStyles(styles)(Events)),
);
