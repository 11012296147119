import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const GuestRow = ({
  index,
  firstName,
  lastName,
  plusOne,
  allergies,
  handleUpdate,
}) => {
  if (plusOne) {
    return (
      <>
        <Grid item xs={3}>
          <TextField
            value={firstName}
            label="First Name"
            fullWidth
            onChange={e => handleUpdate(index, "FirstName", e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={lastName}
            label="Last Name"
            fullWidth
            onChange={e => handleUpdate(index, "LastName", e.target.value)}
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            value={allergies}
            label="Allergies and/or Restrictions"
            placeholder="None"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={e => handleUpdate(index, "Allergies", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item xs={7}>
          <TextField
            value={`${firstName} ${lastName}`}
            label="Guest"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item xs={5}>
          <TextField
            value={allergies}
            label="Allergies and/or Dietary Restrictions"
            placeholder="None"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={e => handleUpdate(index, "Allergies", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </>
    );
  }
};

const RSVPGuest = ({ guests, handleUpdate }) => {
  // console.log(guests);
  return (
    <div>
      <Typography variant="subtitle1">
        Please make sure we have the name of any guests you are bringing with
        you. Let us know how we can accommodate any food allergies,
        restrictions, or sensitivities.
      </Typography>
      <hr />
      <Grid container spacing={2}>
        {(guests.Guests || []).map((guest, index) => (
          <GuestRow
            key={index}
            index={index}
            firstName={guest.FirstName}
            lastName={guest.LastName}
            allergies={guest.Allergies}
            plusOne={guest.PlusOne}
            handleUpdate={handleUpdate}
          />
        ))}
      </Grid>
    </div>
  );
};

export default RSVPGuest;
