import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Grid, Button } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import { withFirebase } from "../../Firebase";
import { requestEvents } from "../../../actions/eventsActions";
import { requestGuests, getGuestCounts } from "../../../actions/guestsActions";
import TableCard from "./TableCard";
import { useParams } from "react-router-dom";
import { unFirebaseObject } from "../../../util/firebaseUtil";
import { updateGuest } from "../../../actions/guestsActions";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(2),
    },
    body: {
      paddingTop: theme.spacing(2),
    },
    backIcon: {
      fontSize: theme.typography.h3.fontSize,
    },
    headline: {
      display: "inline-block",
      verticalAlign: "middle",
    },
    button: {
      float: "right",
      verticalAlign: "middle",
    },
  };
});

const SeatingEdit = ({
  guests: guestsObj,
  events: eventsObj,
  dispatch,
  firebase,
}) => {
  let { id } = useParams();
  const classes = useStyles();
  const [guestList, setGuestList] = useState({});
  const [guests, setGuests] = useState({});
  const [event, setEvent] = useState(null);
  const [dirty, setDirty] = useState([]);
  const { events } = eventsObj;
  useEffect(() => {
    dispatch(requestEvents(firebase));
    dispatch(requestGuests(firebase));
  }, [dispatch, firebase]);

  useEffect(() => {
    setGuestList(unFirebaseObject(guestsObj.guests || {}));
  }, [guestsObj.guests]);

  useEffect(() => {
    if (event) {
      setGuests(generateGuestList(guestList || {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestList, event]);

  useEffect(() => {
    setEvent(events[id]);
  }, [events, id]);

  const save = () => {
    dirty.forEach(id => {
      const newGuest = { ...guestsObj.guests[id] };
      const changed = guestList[id];
      changed.Guests.forEach((guest, index) => {
        newGuest.Guests[index].TableAssignment = guest.TableAssignment || {};
      });
      console.log(guestsObj.guests[id]);
      console.log(newGuest);
      dispatch(updateGuest(firebase, id, newGuest));
      // dispatch(updateGuest(firebase, id, guestList[id]));
    });
    setDirty([]);
    // history.push("/admin/seating");
  };

  const moveGuest = (table, index, key) => {
    if (guestList[key].Guests[index].TableAssignment) {
      guestList[key].Guests[index].TableAssignment[event.id] = table;
    } else {
      guestList[key].Guests[index].TableAssignment = { [event.id]: table };
    }
    setGuestList({ ...guestList });
    setDirty((dirty || []).concat(key));
  };

  const generateGuestList = gu => {
    const newGuests = {};
    Object.entries(gu || {}).forEach(([key, family]) => {
      (family.Guests || []).forEach((guest, index) => {
        var invited = false;
        var attending = false;
        var declined = false;
        let assigned = false;
        // has this guest been invited to this event?
        (guest.InvitedTo || []).forEach(e => {
          if (
            e._key.path.segments[e._key.path.segments.length - 1] === event.id
          ) {
            invited = true;
          }
        });
        if (invited) {
          (guest.Attending || []).forEach(e => {
            if (
              e._key.path.segments[e._key.path.segments.length - 1] === event.id
            ) {
              attending = true;
            }
          });
          (guest.NotAttending || []).forEach(e => {
            if (
              e._key.path.segments[e._key.path.segments.length - 1] === event.id
            ) {
              declined = true;
            }
          });
          // does this guest have a table assignment

          if (guest.TableAssignment && guest.TableAssignment[event.id]) {
            const t = guest.TableAssignment[event.id];
            assigned = true;
            // if this person is assigned to an invalid table number, show them as unassigned
            if (t.table > event.SeatingTableCounts) {
              assigned = false;
            } else {
              newGuests[t] = (newGuests[t] || []).concat([
                {
                  key,
                  index,
                  guest,
                  invited,
                  attending,
                  declined,
                  familyName: family.ShortName,
                },
              ]);
            }
          }
          if (!assigned) {
            newGuests.unassigned = (newGuests["unassigned"] || []).concat([
              {
                key,
                index,
                guest,
                invited,
                attending,
                declined,
                familyName: family.ShortName,
              },
            ]);
          }
        }
        // console.log(key, index, guest, invited, attending, declined, assigned);
      });
    });
    return newGuests;
  };

  return (
    <div className={classes.root}>
      <IconButton component={Link} to="/admin/seating">
        <BackIcon className={classes.backIcon} />
      </IconButton>
      <Button
        className={classes.button}
        size="large"
        variant="contained"
        color="primary"
        disabled={(dirty || []).length === 0}
        onClick={save}
      >
        Save
      </Button>
      <Typography className={classes.headline} variant="h2">
        Seating{event && `: ${event.Title}`}
      </Typography>
      <Grid container className={classes.body} spacing={2}>
        {eventsObj.loading || guestsObj.loading ? (
          <Grid item xs={12}>
            <Typography variant="h4">Loading...</Typography>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TableCard
                title={"Unassigned"}
                guests={guests.unassigned}
                numberOfSeats={null}
                tableNumber={null}
                moveGuest={moveGuest}
                tables={[{ title: "Unassigned", id: null }].concat(
                  [
                    ...Array((event && event.SeatingTableCounts) || 0).keys(),
                  ].map(index => ({
                    title: `Table ${index + 1}`,
                    id: index + 1,
                  })),
                )}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                {[...Array((event && event.SeatingTableCounts) || 0).keys()]
                  //.map(i => i + 1)
                  .map(index => {
                    return (
                      <Grid key={index} item xs={12} md={4}>
                        <TableCard
                          title={`Table ${index + 1}`}
                          guests={guests[index + 1]}
                          numberOfSeats={event.SeatingTableSeats}
                          tableNumber={index + 1}
                          moveGuest={moveGuest}
                          tables={[{ title: "Unassigned", id: null }].concat(
                            [
                              ...Array(
                                (event && event.SeatingTableCounts) || 0,
                              ).keys(),
                            ].map(index => ({
                              title: `Table ${index + 1}`,
                              id: index + 1,
                            })),
                          )}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const { events, guests } = state;
  return {
    events,
    eventCounts: getGuestCounts(state),
    guests,
  };
};

export default connect(mapStateToProps)(withFirebase(SeatingEdit));
