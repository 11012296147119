import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import HelpIcon from "@material-ui/icons/Help";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  hr: {
    margin: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  row: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const TableCard = ({
  title,
  guests,
  tables,
  tableNumber,
  numberOfSeats,
  moveGuest,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, index, key) => {
    setAnchorEl({ target: event.currentTarget, index, key });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = table => {
    moveGuest(table, anchorEl.index, anchorEl.key);
    setAnchorEl(null);
  };
  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  console.log(guests);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl && anchorEl.target}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(tables || []).map(t => {
          return (
            <MenuItem
              key={t.id}
              onClick={() => handleSelect(t.id)}
              disabled={t.id === tableNumber}
            >
              {t.title}
            </MenuItem>
          );
        })}
      </Menu>
      <Paper className={classes.root}>
        <Typography variant="h6">
          {title}{" "}
          {numberOfSeats && (
            <small>
              {(guests || []).length} of {numberOfSeats}
            </small>
          )}
        </Typography>
        <hr className={classes.hr} />
        <List component="nav" aria-label="guests">
          {(guests || []).map(guest => {
            let guestName = `${guest.guest.FirstName} ${guest.guest.LastName}`;
            if (
              (!guest.guest.FirstName || guest.guest.FirstName === "") &&
              (!guest.guest.LastName || guest.guest.LastName === "")
            ) {
              guestName = `${guest.familyName} +1`;
            }
            return (
              <ListItem
                className={classes.row}
                key={`${guest.key}-${guest.index}`}
                button
                onContextMenu={e => {
                  handleClick(e, guest.index, guest.key);
                  e.preventDefault();
                }}
                onClick={e => {
                  handleClick(e, guest.index, guest.key);
                  e.preventDefault();
                }}
              >
                <ListItemIcon>
                  {guest.attending ? (
                    <CheckIcon />
                  ) : guest.declined ? (
                    <ClearIcon />
                  ) : (
                    <HelpIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={guestName}
                  secondary={guest.familyName}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </>
  );
};

TableCard.propTypes = {};

export default TableCard;
