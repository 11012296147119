import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {},
  buttons: {
    textAlign: "right",
    paddingTop: theme.spacing(2),
  },
}));

const AddressDialog = ({ handleClose, handleSave, open, address, id }) => {
  const classes = useStyles();
  const [updAddress, setUpdAddress] = useState({});

  useEffect(() => {
    setUpdAddress({});
  }, [address, id]);

  const saveRecord = () => {
    handleSave({ ...address, ...updAddress });
  };

  const updateField = e => {
    setUpdAddress({ ...updAddress, [e.target.id]: e.target.value });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Edit Address</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Address"
              id="Address1"
              value={updAddress.Address1 || address.Address1 || ""}
              onChange={updateField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address 2"
              id="Address2"
              value={updAddress.Address2 || address.Address2 || ""}
              onChange={updateField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              id="City"
              value={updAddress.City || address.City || ""}
              onChange={updateField}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField
              label="State"
              id="State"
              value={updAddress.State || address.State || ""}
              onChange={updateField}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <TextField
              label="Zip"
              id="Zip"
              value={updAddress.Zip || address.Zip || ""}
              onChange={updateField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color="primary" onClick={saveRecord}>
                Save
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AddressDialog.propTypes = {};

export default AddressDialog;
