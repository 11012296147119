import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Checkbox, Select, MenuItem } from "@material-ui/core";
import tableIcons from "../../ui/tableIcons";
import { unFirebaseObject } from "../../../util/firebaseUtil";
import { withFirebase } from "../../Firebase";

const GuestEditTable = ({
  loading,
  guests,
  events,
  updateGuests,
  firebase,
}) => {
  const [guestTable, setGuestTable] = useState([]);

  //console.log(guests);
  const handleAdd = newData => {
    const newGuest = {
      ...newData,
      Attending: [],
      InvitedTo: [],
      NotAttending: [],
    };
    Object.keys(newData).forEach(key => {
      if (key.includes("_")) {
        const eName = key.replace("_", "");
        const status = newGuest[key];
        let eventKey = "";
        Object.entries(events.events).forEach(([key, val]) => {
          if (val.ShortName === eName) {
            eventKey = key;
          }
        });
        if (status === "-") {
          // not invited, so remove all references - do nothing
        } else if (status === "Invited") {
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          //console.log("Invited", newGuest.Invited);
          // invited so add to invited list and remove from attending/declined
        } else if (status === "Declined") {
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          newGuest.NotAttending.push(firebase.getDoc(`events/${eventKey}`));
          // declined the invitation so remove from attending add to invited
        } else if (status === "Attending") {
          // attending remove from declined add to attending/invited
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          newGuest.Attending.push(firebase.getDoc(`events/${eventKey}`));
        }
        //console.log(eName, status, newGuest);
        delete newGuest[key];
      }
    });
    guests.push(newGuest);
    updateGuests([...guests]);
  };

  const handleUpdate = (newData, oldData) => {
    //console.log(newData, oldData);
    const newGuest = {
      ...newData,
      Attending: [],
      InvitedTo: [],
      NotAttending: [],
    };
    Object.keys(newData).forEach(key => {
      if (key.includes("_")) {
        const eName = key.replace("_", "");
        const status = newGuest[key];
        let eventKey = "";
        Object.entries(events.events).forEach(([key, val]) => {
          if (val.ShortName === eName) {
            eventKey = key;
          }
        });
        if (status === "-") {
          // not invited, so remove all references - do nothing
        } else if (status === "Invited") {
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          //console.log("Invited", newGuest.Invited);
          // invited so add to invited list and remove from attending/declined
        } else if (status === "Declined") {
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          newGuest.NotAttending.push(firebase.getDoc(`events/${eventKey}`));
          // declined the invitation so remove from attending add to invited
        } else if (status === "Attending") {
          // attending remove from declined add to attending/invited
          newGuest.InvitedTo.push(firebase.getDoc(`events/${eventKey}`));
          newGuest.Attending.push(firebase.getDoc(`events/${eventKey}`));
        }
        //console.log(eName, status, newGuest);
        delete newGuest[key];
      }
    });
    guests[oldData.tableData.id] = newGuest;
    updateGuests([...guests]);
  };

  const handleDelete = oldData => {
    //console.log(oldData);
    guests.splice(oldData.tableData.id, 1);
    updateGuests([...guests]);
  };

  useEffect(() => {
    let newGuests = unFirebaseObject(guests);
    if (events && events.events) {
      (Object.values(events.events) || []).forEach(event => {
        //console.log(newGuests);
        newGuests = (newGuests || []).map(row => {
          let attending = false;
          let declined = false;
          let invited = false;
          (row.InvitedTo || []).forEach(att => {
            let id = null;
            if (att && att._key && att._key.path && att._key.path.segments) {
              id = att._key.path.segments[att._key.path.segments.length - 1];
            }
            if (id === event.id) {
              invited = true;
            }
          });
          if (!invited) {
            row[`_${event.ShortName}`] = "-";
          } else {
            (row.Attending || []).forEach(att => {
              let id = null;
              if (att && att._key && att._key.path && att._key.path.segments) {
                id = att._key.path.segments[att._key.path.segments.length - 1];
              }
              if (id === event.id) {
                attending = true;
              }
            });
            (row.NotAttending || []).forEach(att => {
              let id = null;
              if (att && att._key && att._key.path && att._key.path.segments) {
                id = att._key.path.segments[att._key.path.segments.length - 1];
              }
              if (id === event.id) {
                declined = true;
              }
            });
            row[`_${event.ShortName}`] = attending
              ? "Attending"
              : declined
              ? "Declined"
              : "Invited";
          }
          return row;
        });
      });
    }
    //console.log(newGuests);
    setGuestTable(newGuests);
  }, [guests, events]);

  const generateColumns = () => {
    const columns = [
      { title: "First Name", field: "FirstName" },
      { title: "Last Name", field: "LastName" },
      {
        title: "+1",
        field: "PlusOne",
        // eslint-disable-next-line react/display-name
        render: row => {
          return <Checkbox disabled checked={row.PlusOne}></Checkbox>;
        },
        // eslint-disable-next-line react/display-name
        editComponent: props => {
          return (
            <Checkbox
              checked={props.value || false}
              onChange={e => {
                props.onChange(e.target.checked);
                console.log(e.target.checked);
              }}
            />
          );
        },
      },
    ];
    Object.values(events.events || {}).forEach(event => {
      columns.push({
        title: `Event: ${event.ShortName}`,
        field: `_${event.ShortName}`,
        // eslint-disable-next-line react/display-name
        editComponent: props => {
          return (
            <Select
              fullWidth
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
            >
              <MenuItem value="-">Not Invited</MenuItem>
              <MenuItem value="Invited">Invited</MenuItem>
              <MenuItem value="Attending">Attending</MenuItem>
              <MenuItem value="Declined">Declined</MenuItem>
            </Select>
          );
        },
      });
    });
    columns.push({ title: "Allergies/Requests", field: "Allergies" });
    return columns;
  };
  return (
    <MaterialTable
      isLoading={loading}
      icons={tableIcons}
      style={{
        marginRight: 24,
      }}
      options={{
        actionsColumnIndex: 7,
        search: false,
        paging: false,
      }}
      // actions={[]}
      columns={generateColumns()}
      data={guestTable}
      title="Guest List"
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            handleAdd(newData);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            handleUpdate(newData, oldData);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            handleDelete(oldData);
            resolve();
          }),
      }}
    />
  );
};

export default withFirebase(GuestEditTable);
