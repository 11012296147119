import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LoadingOverlay from "../ui/LoadingOverlay";

const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: 20,
  },
});

const LoginForm = ({ error, multiples, errorMessage, doLogin, loading }) => {
  const classes = useStyles();
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  return (
    <Grid container>
      <Grid item md={3} sm={1} xs={false} />
      <Grid item md={6} sm={10} xs={12}>
        <LoadingOverlay loading={loading}>
          <Paper className={classes.root}>
            <h4>Please Log In</h4>
            {error && (
              <Typography variant="subtitle2" color="secondary">
                {errorMessage}
              </Typography>
            )}

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  value={lastName}
                  label="Last Name"
                  onChange={e => setLastName(e.target.value)}
                ></TextField>
              </Grid>
              {multiples && (
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    value={streetNumber}
                    onChange={e => setStreetNumber(e.target.value)}
                    label="Street Number"
                  ></TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  className={classes.textField}
                  value={zipCode}
                  onChange={e => setZipCode(e.target.value)}
                  onKeyPress={e => {
                    if (e.which === 13 || e.keyCode === 13) {
                      doLogin(lastName, zipCode, streetNumber);
                      return false;
                    }
                    return true;
                  }}
                  label="Zip Code"
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  onClick={() => doLogin(lastName, zipCode, streetNumber)}
                  variant="contained"
                >
                  Log In
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </LoadingOverlay>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
