import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  centered: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
}));

const SeatingEventCard = ({ eventName, eventId, seated, invited }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography className={classes.centered} variant="h4">
        {eventName}
      </Typography>
      <Typography className={classes.centered} variant="h5">
        {seated} <small>Guests Seated</small>
      </Typography>
      <Typography className={classes.centered} variant="h5">
        {invited} <small>Guests Invited</small>
      </Typography>
      <div className={classes.right}>
        <Button
          className={classes.right}
          component={Link}
          variant="contained"
          color="primary"
          to={`/admin/seating/${eventId}`}
        >
          Edit
        </Button>
      </div>
    </Paper>
  );
};

SeatingEventCard.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default SeatingEventCard;
