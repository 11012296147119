import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  centered: {
    textAlign: "center",
  },
  body: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttons: {
    textAlign: "right",
    padding: theme.spacing(1),
  },
  hr: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
});

const EventCard = ({
  id,
  classes,
  title,
  description,
  date,
  attending,
  invited,
  noResponse,
  shortName,
  editEvent,
  deleteEvent,
  seating,
  seatingTableSeats,
  seatingTableCounts,
  embedded,
}) => {
  let dateString = "No Date Set";
  let timeString = null;
  if (date && date.seconds) {
    dateString = moment.unix(date.seconds).format("MMMM Do, YYYY");
    timeString = moment.unix(date.seconds).format("h:mma");
  }
  return (
    <Paper>
      <div className={classes.root}>
        <Typography className={classes.centered} variant="h4">
          {title}
        </Typography>
        <Typography className={classes.centered} variant="subtitle1">
          ({shortName})
        </Typography>
        <Typography className={classes.centered} variant="subtitle2">
          {timeString}
        </Typography>
        <Typography className={classes.centered} variant="subtitle1">
          {dateString}
        </Typography>
        <Grid container className={classes.body}>
          <Grid item xs={4}>
            <Typography className={classes.centered} variant="h3">
              {attending}
            </Typography>
            <Typography className={classes.centered} variant="subtitle2">
              Attending
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.centered} variant="h3">
              {invited}
            </Typography>
            <Typography className={classes.centered} variant="subtitle2">
              Invited
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.centered} variant="h3">
              {noResponse}
            </Typography>
            <Typography className={classes.centered} variant="subtitle2">
              No Response
            </Typography>
          </Grid>
        </Grid>
        {!embedded && (
          <Grid item xs={12}>
            <Typography className={classes.body} variant="body1">
              {description}
            </Typography>
          </Grid>
        )}
        {seating && !embedded && (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              <strong>Seating: </strong>
              {seatingTableCounts} Tables of {seatingTableSeats}
            </Typography>
          </Grid>
        )}
      </div>
      {!embedded && (
        <>
          <hr className={classes.hr} />
          <div className={classes.buttons}>
            <Button
              variant="text"
              color="secondary"
              className={classes.button}
              // endIcon={<EditIcon>Edit</EditIcon>}
              onClick={() => deleteEvent(id)}
            >
              Delete
            </Button>
            <Button
              variant="text"
              color="primary"
              className={classes.button}
              // endIcon={<EditIcon>Edit</EditIcon>}
              onClick={() => editEvent(id)}
            >
              Edit
            </Button>
          </div>
        </>
      )}
    </Paper>
  );
};

EventCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.shape({
    seconds: PropTypes.number,
  }),
  attending: PropTypes.number,
  invited: PropTypes.number,
  noResponse: PropTypes.number,
  editEvent: PropTypes.func.isRequired,
  seating: PropTypes.bool,
  seatingTableCounts: PropTypes.number,
  seatingTableSeats: PropTypes.number,
};

EventCard.defaultProps = {
  title: "",
  description: "",
  date: null,
  attending: 0,
  invited: 0,
  noResponse: 0,
  seating: false,
  seatingTableCounts: 0,
  seatingTableSeats: 0,
  embedded: false,
};

export default withStyles(styles)(EventCard);
